import API from '../Api';

//===========================================
//APIs

const UserApi = {};

UserApi.getAll = () => API.get('UserAccount/GetUser');
UserApi.getItem = (id) => API.get('UserAccount/Get?UserId=' + id);
UserApi.add = (data) => API.post('UserAccount/AddUser', data);
UserApi.edit = (data) => API.post('UserAccount/EditUser', data);
UserApi.delete = (id) => API.get('UserAccount/Delete?UserId=' + id);
UserApi.setStatus = (data) => API.post('UserAccount/EditStatus', data);
UserApi.setRole = (data) => API.post('UserAccount/EditRole?userId=' + data.userId + '&roles=' + data.roles);
// UserApi.changePassword = (data) => API.post('UserAccount/ChangePassword?userId=' + data.userId + '&NewPassword=' + data.password);
UserApi.changePassword = (data) => API.post('UserAccount/ChangePassword?NewPassword=' + data.password);
UserApi.getCurrentUser = () => API.get('UserAccount/CurrentUser');
UserApi.getNotMappedProviders = () => API.get('UserAccount/GetNotMappedProviders');
// UserApi.search = (data) => API.post('UserAccount/search', data);

export default UserApi;
