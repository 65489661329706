import axios from "axios";
export const CancelToken = axios.CancelToken;
export let setSession = () => {
   localStorage.setItem(
      "userStatus",
      JSON.stringify({
         par:
            "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIxIiwiVXNlck5hbWUiOiJsYWJhZG1pbkBrZXlmcmFtZS1lZy5jb20iLCJFbWFpbCI6IiIsIlBob25lIjoiIiwiZXhwIjo3MjYwMDc5MTA5LjAsIm5iZiI6NzI5MTY4NjUwOS4wLCJpYXQiOjcyNjAwNjQxMDkuMH0.zjICSrOkOn6zvVgaqMqjSCSGR863bSeCcDWCfTGLgdPq_vnNQosXtTxtjQyXd5jAXv_in_g1OdU_pKZzY_J0mw",
      })
   );
};
const lstrg = localStorage.getItem("userStatus");
const par = lstrg ? JSON.parse(lstrg).par : "";

export default axios.create({
   baseURL: process.env.REACT_APP_BASEURL || `https://lab.key-frame.cf/api/`,
   headers: { Authorization: par ? "Bearer " + par : "" },
});

//========================================
export const axe = axios;

const axiosConfig = (par, params = {}) => {
   return {
      baseURL: `https://lab.key-frame.cf/api/`,
      headers: { Authorization: par ? "Bearer " + par : "" },
      params,
   };
};

export const getRequest = async ({ target = "", params = {} }) => {
   if (!target) return console.log("No ajax Target url");

   let Par = localStorage.getItem("par");

   try {
      let req = await axios.get(target, axiosConfig(Par, params));

      if (req.data.isDone) return req.data;
   } catch (error) {
      console.log("Error: ", error);
   }
};

export const postRequest = async ({ target = "", data = {} }) => {
   if (!target && !data) return console.log("No ajax Target url && data");

   let Par = localStorage.getItem("par");

   try {
      let req = await axios.post(target, data, axiosConfig(Par));

      if (req.data.isDone) return req.data;
   } catch (error) {
      console.log("Error: ", error);
   }
};
