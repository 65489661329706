import message from "antd/es/message";
import PermissionActionTypes from "./ActionTypes";
import PermissionApi from "../../../shared/DataService/Permission";
import { store } from "../../Store/Store";
import { NotAuthorizedAction } from "../Authenticate/NotAuthorizedActions";

//============================================

// Get All Permission
export const fetchAllPermissions = () => async (dispatch) => {
   let permissionList = store.getState().permissions;

   // if permissionList exist in store, return it
   if (permissionList.length)
      return dispatch(allPermissionAction(permissionList));

   permissionList = await PermissionApi.getAll();

   try {
      // console.log("d: ", permissionList);
      if (permissionList.data.isDone) {
         return dispatch(allPermissionAction(permissionList.data.data));
      } else {
         if (message)
            message.error(
               permissionList.data.message +
                  " Couldn't be gotten! is Done: " +
                  permissionList.data.isDone
            );
      }
   } catch (e) {
      if (message) {
         dispatch(NotAuthorizedAction());
         message.error("Something went wrong in Fetch All Action " + e);
      }
      // throw (e);
   }
};

// Get Permission By Role
export const fetchPermissionByRole = (role) => async (dispatch) => {
   if (!role) return;

   try {
      let permission = await PermissionApi.getByRole(role);

      if (permission.data.isDone) {
         return dispatch(permissionRoleAction(permission.data.data));
      } else {
         if (message)
            message.error(
               permission.data.message +
                  " Couldn't be gotten! is Done: " +
                  permission.data.isDone
            );
      }
   } catch (e) {
      if (message) {
         dispatch(NotAuthorizedAction());
         message.error("Something went wrong in fetch item Action " + e);
      }
      // throw (e);
   }
};

// Add Role Permission
export const addRolePermission = (role, data) => async (dispatch) => {
   if (!role || !data) return;

   try {
      let permission = await PermissionApi.addRolePermission(role, data);

      if (permission.data.isDone) {
         return dispatch(permissionAddRoleAction([...data]));
      } else {
         if (message)
            message.error(
               permission.data.message +
                  " Couldn't be Added! is Done: " +
                  permission.data.isDone
            );
      }
   } catch (e) {
      if (message) {
         dispatch(NotAuthorizedAction());
         message.error("Something went wrong in Add Action " + e);
      }
      // throw (e);
   }
};

// Get Permission By User
export const fetchPermissionByUser = (userId) => async (dispatch) => {
   if (!userId) return;

   try {
      let permission = await PermissionApi.getByUser(userId);

      if (permission.data.isDone) {
         return dispatch(permissionUserAction(permission.data.data));
      } else {
         if (message)
            message.error(
               permission.data.message +
                  " Couldn't be gotten! is Done: " +
                  permission.data.isDone
            );
      }
   } catch (e) {
      if (message) {
         dispatch(NotAuthorizedAction());
         message.error("Something went wrong in fetch item Action " + e);
      }
      // throw (e);
   }
};

// Add Role Permission
export const addUserPermission = (userId, data) => async (dispatch) => {
   if (!userId || !data) return;

   try {
      let permission = await PermissionApi.addUserPermission(userId, data);

      if (permission.data.isDone) {
         return dispatch(permissionAddUserAction([...data]));
      } else {
         if (message)
            message.error(
               permission.data.message +
                  " Couldn't be Added! is Done: " +
                  permission.data.isDone
            );
      }
   } catch (e) {
      if (message) {
         dispatch(NotAuthorizedAction());
         message.error("Something went wrong in Add Action " + e);
      }
      // throw (e);
   }
};

//===============================================
// Actions
export const allPermissionAction = (payload) => {
   return {
      type: PermissionActionTypes.FETCH_PERMISSIONS,
      payload,
   };
};

export const permissionRoleAction = (payload) => {
   return {
      type: PermissionActionTypes.FETCH_PERMISSION_BY_ROLE,
      payload,
   };
};

export const permissionAddRoleAction = (payload) => {
   return {
      type: PermissionActionTypes.ADD_ROLE_PERMISSION,
      payload,
   };
};

export const permissionUserAction = (payload) => {
   return {
      type: PermissionActionTypes.FETCH_PERMISSION_BY_USER,
      payload,
   };
};

export const permissionAddUserAction = (payload) => {
   return {
      type: PermissionActionTypes.ADD_USER_PERMISSION,
      payload,
   };
};

// store.subscribe(()=> console.log(store.getState()));
