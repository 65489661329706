import React, {
   createContext,
   useState,
   useEffect /*, lazy, memo*/,
} from "react";
// import Api, { axe } from "./shared/Api";
import { fetchPermissionByRole } from "../redux/Actions/Permission/PermissionActions";
import { useDispatch, useSelector } from "react-redux";
import AllPermissions from "./allPermissions";
import { setCurrentUserPerm } from "../redux/Actions/User/UserActions";

export const PermissionsContext = createContext();

const UserPermissionsProvider = (props) => {
   let users = useSelector((state) => state.users);
   let dispatch = useDispatch();
   // let lstrg = sessionStorage.getItem('userStatus');
   let lstrg = localStorage.getItem("userStatus");

   let userStatus = lstrg && JSON.parse(lstrg);
   // let ability = new Ability();

   const [userPerms, setUserPerms] = useState(
      (userStatus && userStatus.perms && atob(userStatus.perms).split(",")) ||
         AllPermissions.filter(
            (perm) =>
               perm.search(
                  /^(reservation).*?(edit|get)|^(vacation).*?(delete|get)|^(document).*?(delete|get)/gi
               ) > -1
         ) ||
         []
   );
   const [permsLoading, setPermsLoading] = useState(false);

   const getPermissionByRole = async () => {
      setPermsLoading(true);
      let disp = async () =>
         dispatch(
            fetchPermissionByRole(
               userStatus && atob(userStatus.role).split("_")[1]
            )
         );
      let getF = await disp();
      if (getF) {
         setUserPerms(getF.payload);
         userStatus.perms = btoa(getF.payload);
         // sessionStorage.setItem('userStatus', JSON.stringify(userStatus));
         localStorage.setItem("userStatus", JSON.stringify(userStatus));
         let fet = async () => dispatch(setCurrentUserPerm(userStatus.perms));
         await fet();
      }
      setPermsLoading(false);
   };

   useEffect(() => {
      try {
         if (userStatus && !userStatus.perms) getPermissionByRole();
      } catch (error) {}

      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      // let sstrg = sessionStorage.getItem('userStatus');
      let sstrg = localStorage.getItem("userStatus");
      let userState = sstrg && JSON.parse(sstrg);
      let tout = 0;
      try {
         if (userState && userState.perms) {
            setPermsLoading(true);
            tout = setTimeout(() => {
               setUserPerms(atob(userStatus.perms).split(",") || userPerms);
               setPermsLoading(false);
               clearTimeout(tout);
            }, 1500);
         }
         // getPermissionByRole();
      } catch (error) {
         console.log(error);
      }

      return () => clearTimeout(tout);

      // eslint-disable-next-line
   }, [users.currentUserPerm]);

   return (
      <PermissionsContext.Provider
         value={{
            userPerms: [...userPerms],
            AllPermissions: AllPermissions,
            permsLoading,
         }}
      >
         {props.children}
      </PermissionsContext.Provider>
   );
};

export default UserPermissionsProvider;
