import message from "antd/es/message";
import UserActionTypes from "./ActionTypes";
import UserApi from "../../../shared/DataService/User";
import { store } from "../../Store/Store";
import { NotAuthorizedAction } from "../Authenticate/NotAuthorizedActions";

//============================================

//Get All
export const fetchAllUsers = () => async (dispatch) => {
   let list = store.getState().users.list;

   // if list exist in store, return it
   // if(list.length) return dispatch(getAllUsersAction(list));

   try {
      list = await UserApi.getAll();

      // console.log("d: ", list);
      if (list.data.isDone) {
         return dispatch(getAllUsersAction(list.data.data));
      } else {
         if (message)
            message.error(
               list.data.message +
                  " Couldn't be gotten! is Done: " +
                  list.data.isDone
            );
      }
   } catch (e) {
      if (message) {
         dispatch(NotAuthorizedAction());
         message.error("Something went wrong in Fetch All Action " + e);
      }
   }
};

// Get Item
export const fetchUserItem = (id) => async (dispatch) => {
   if (!id) return;

   try {
      let item = await UserApi.getItem(id);

      if (item.data.isDone) {
         return dispatch(getUserItemAction(item.data.listOfData));
      } else {
         if (message)
            message.error(
               item.data.message +
                  " Couldn't be gotten! is Done: " +
                  item.data.isDone
            );
      }
   } catch (e) {
      if (message) {
         dispatch(NotAuthorizedAction());
         message.error("Something went wrong in fetch item Action " + e);
      }
   }
};

// Add Item
export const addUserItem = (data) => async (dispatch) => {
   if (!data) return;

   try {
      let item = await UserApi.add(data);

      if (item.data.isDone) {
         return dispatch(addUserAction({ ...data, id: item.data.outId }));
      } else {
         if (message)
            message.error(
               item.data.message +
                  " Couldn't be Added! is Done: " +
                  item.data.isDone
            );
      }
   } catch (e) {
      if (message) message.error("Something went wrong in Add Action " + e);
   }
};

// Edit Item
export const editUserItem = (data) => async (dispatch) => {
   if (!data) return;

   try {
      let item = await UserApi.edit(data);

      if (item.data.isDone) {
         return dispatch(editUserAction({ ...data, id: data.id }));
      } else {
         if (message)
            message.error(
               item.data.message +
                  " Couldn't be Edited! is Done: " +
                  item.data.isDone
            );
      }
   } catch (e) {
      if (message) message.error("Something went wrong in th Edit Action " + e);
   }
};

// Delete Item
export const deleteUserItem = (data) => async (dispatch) => {
   if (!data) return;

   try {
      let item = await UserApi.delete(data.id);

      if (item.data.isDone) {
         return dispatch(deleteUserAction(data));
      } else {
         if (message)
            message.error(
               item.data.message +
                  " Couldn't be deleted! is Done: " +
                  item.data.isDone
            );
      }
   } catch (e) {
      if (message) message.error("Something went wrong in Delete Action " + e);
   }
};

// set status
export const userSetStatus = (data) => async (dispatch) => {
   if (!data) return;

   try {
      let item = await UserApi.setStatus(data);

      if (item.data.isDone) {
         return dispatch(setStatusUserAction(data));
      } else {
         if (message)
            message.error(
               item.data.message +
                  " Couldn't be changed!, is Done: " +
                  item.data.isDone
            );
      }
   } catch (e) {
      if (message) message.error("Something went wrong in Action " + e);
   }
};

// set role
export const userSetRole = (data) => async (dispatch) => {
   if (!data) return;

   try {
      let item = await UserApi.setRole(data);

      if (item.data.isDone) {
         return dispatch(setRoleUserAction(data));
      } else {
         if (message)
            message.error(
               item.data.message +
                  " Couldn't be changed!, is Done: " +
                  item.data.isDone
            );
      }
   } catch (e) {
      if (message) message.error("Something went wrong in Action " + e);
   }
};

// change pass
export const userChangePassword = (data) => async (dispatch) => {
   if (!data) return;

   try {
      let item = await UserApi.changePassword({
         userId: data.id,
         password: data.password,
      });

      if (item.data.isDone) {
         return dispatch(changePasswordUserAction(data));
      } else {
         if (message)
            message.error(
               item.data.message +
                  " Couldn't be changed!, is Done: " +
                  item.data.isDone
            );
      }
   } catch (e) {
      if (message) message.error("Something went wrong in Action " + e);
      // throw (e);
   }
};

// get curr
export const userGetCurrentUser = () => async (dispatch) => {
   // let user = store.getState().currentUser;

   try {
      let item = await UserApi.getCurrentUser();

      if (item.data.isDone) {
         return dispatch(getCurrentUserAction(item.data.listOfData));
      } else {
         if (message)
            message.error(
               item.data.message +
                  " Couldn't be gotten!, is Done: " +
                  item.data.isDone
            );
      }
   } catch (e) {
      if (message) message.error("Something went wrong in Action " + e);
   }
};

// set curr perm
export const setCurrentUserPerm = (perm) => async (dispatch) => {
   // let userPerm = store.getState().currentUserPerm;

   try {
      // let item = await UserApi.getCurrentUser();

      // if (item.data.isDone) {
      return dispatch(setCurrentUserPermAction(perm));
      // } else {
      //     if (message) message.error(item.data.message + " Couldn't be gotten!, is Done: "+ item.data.isDone);
      // }
   } catch (e) {
      if (message) message.error("Something went wrong in Action " + e);
      // throw (e);
   }
};

// get not mapped
export const userGetNotMappedProviders = () => async (dispatch) => {
   // let list = store.getState().users.notMapped;

   // // if list exist in store, return it
   // if(list.length) return dispatch(getNotMappedProvidersAction(list));

   try {
      let item = await UserApi.getNotMappedProviders();

      if (item.data.isDone) {
         return dispatch(getNotMappedProvidersAction(item.data.listOfData));
      } else {
         if (message)
            message.error(
               item.data.message +
                  " Couldn't be gotten!, is Done: " +
                  item.data.isDone
            );
      }
   } catch (e) {
      if (message) message.error("Something went wrong in Action " + e);
   }
};

// Search
// export const search = (data) => async (dispatch) => {
//     if (!data) return;

//     let list = await UserApi.search(data);

//     try {
//         if (list.data.isDone) {
//             return dispatch(searchAction(data));
//         } else {
//             if (message) message.error(list.data.message + " Couldn't be Edited! is Done: "+ list.data.isDone);
//         }
//     }
//     catch (e) {
//         if (message) message.error("Something went wrong in th Edit Action "+ e);
//         throw (e);
//     }
// };

//===============================================
// Actions
export const getAllUsersAction = (payload) => {
   return {
      type: UserActionTypes.FETCH_USERS,
      payload,
   };
};

export const getUserItemAction = (payload) => {
   return {
      type: UserActionTypes.FETCH_USER,
      payload,
   };
};

export const addUserAction = (payload) => {
   return {
      type: UserActionTypes.ADD_USER,
      payload,
   };
};

export const editUserAction = (payload) => {
   return {
      type: UserActionTypes.EDIT_USER,
      payload,
   };
};

export const deleteUserAction = (payload) => {
   return {
      type: UserActionTypes.DELETE_USER,
      payload,
   };
};

export const setStatusUserAction = (payload) => {
   return {
      type: UserActionTypes.SET_STATUS,
      payload,
   };
};

export const setRoleUserAction = (payload) => {
   return {
      type: UserActionTypes.SET_ROLE,
      payload,
   };
};

export const changePasswordUserAction = (payload) => {
   return {
      type: UserActionTypes.CHANGE_PASSWORD,
      payload,
   };
};

export const getCurrentUserAction = (payload) => {
   return {
      type: UserActionTypes.GET_CURRENTUSER,
      payload,
   };
};

export const setCurrentUserPermAction = (payload) => {
   return {
      type: UserActionTypes.SET_CURRENTUSER_PERM,
      payload,
   };
};

export const getNotMappedProvidersAction = (payload) => {
   return {
      type: UserActionTypes.GET_NOTMAPPEDPROVIDERS,
      payload,
   };
};

// export const searchUserAction = (payload) => {
//     return {
//         type: UserActionTypes.SEARCH_USER,
//         payload
//     }
// };

// store.subscribe(()=> console.log("Store: ", store.getState()));
