export default [
   "AccountMangment.Register.policy",
   "AccountMangment.CreateRole.policy",
   "AccountMangment.DeleteRole.policy",
   "AdditionalFees.AddOrEdit.policy",
   "AdditionalFees.Get.policy",
   "AdditionalFees.Delete.policy",
   "AuditTrail.GetAudit",
   "AuditTrail.FilterAudit",
   "Lab.AddOrEdit.policy",
   "Lab.GetMatrial.policy",
   "Material.AddOrEdit.policy",
   "Material.GetMatrial.policy",
   "MaterialType.AddOrEdit.policy",
   "MaterialType.GetMatrialType.policy",
   "MaterialType.Delete.policy",
   "Order.AddOrderWithOrderItem.policy",
   "Order.GetOrder.policy",
   "Order.GetCountOrderForlab.policy",
   "Order.GetOrderCost.policy",
   "Order.GetOrderByLabId.policy",
   "Order.Search.policy",
   "Order.ChangeStatus.policy",
   "Order.UpdateInvoice.policy",
   "Order.Delete.policy",
   "OrderItem.AddOrderItem.policy",
   "OrderItem.GetOrderItemByOrder.policy",
   "OrderItem.GetOrderItemById.policy",
   "OrderItem.Delete.policy",
   "PriceList.AddOrEdit.policy",
   "PriceList.GetPrice.policy",
   "PriceList.Delete.policy",
   "Standard.GetStandard.policy",
   "Standard.AddOrEdit.policy",
   "StandardLimit.GetStandardLimit.policy",
   "StandardLimit.AddOrEdit.policy",
   "StandardLimit.Delete.policy",
   "StandardLimit.GetGradesByStandard.policy",
   "SteelReport.Add.policy",
   "SteelReport.Edit.policy",
   "SteelReport.Get.policy",
   "SteelReport.Delete.policy",
   "SteelReport.Approve.policy",
   "SteelReport.Reject.policy",
   "SteelReport.AddReadings.policy",
   "SteelReport.EditReadings.policy",
   "UserAccount.AddUser.policy",
   "UserAccount.EditUser.policy",
   "UserAccount.EditStatus.policy",
   "UserAccount.EditRole.policy",
   "UserAccount.GetUser.policy",
   // "UserAccount.GetNotMappedProviders.policy",
   "UserAccount.CurrentUser.policy",
   "UserAccount.GetUserByRole.policy",
   "UserAccount.ChangePassword",
   "UserRole.List",
   "UserRole.Add",
   "UserRole.Edit",
   "UserRole.Delete",
   "RolePerm.List",
   "RolePerm.GetForUser",
   "RolePerm.SetForUser",
   "RolePerm.GetForRole",
   "RolePerm.SetForRole",
   "Order.Print.policy",
];
